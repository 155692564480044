import { ASSOCIATES } from "./associates";
import { CONTACTS } from "./contacts";
import { PARTNERS } from "./partners";

export const CONTENT = {
  navbar: {
    home: 'Home',
    about: 'Sobre',
    partners: 'Parceiros',
    products: 'Produtos',
    contact: 'Contato',
    associates: 'Associados',
    call: 'Central de atendimento'
  },
  about: {
    breadcrumb: 'Sobre',
    page_title: 'Saiba mais sobre a JAC Grupo',
    image: {
      src: `/assets/logo.png`,
      alt: 'Logo da JAC Grupo'
    },
    title: 'Sobre nós',
    paragraphs: [
      {
        id: 1,
        text: 'Somos uma união de empresários que visam fomentar o comércio local tem o desafio de entregar bons serviços e produtos, numa área específica e selecionada: CONDOMÍNIOS, residenciais e comerciais.'
      },
      {
        id: 2,
        text: 'Acreditamos que o trabalho com excelência pode ajudar nossas empresas a compor a sociedade e a fazer o melhor, primeiramente pelo bairro da Freguesia, e estendendo para a região de Jacarepaguá.'
      }
    ],
    button_label: 'Saiba mais'
  },
  contact: {
    breadcrumb: 'Contato',
    page_title: 'Fale conosco',
    infos: CONTACTS
  },
  partners: {
    breadcrumb: 'Parceiros',
    page_title: 'Conheça nossos parceiros',
    description: 'Entre em contato com nossos parceiros',
    infos: PARTNERS
  },
  associates: {
    breadcrumb: 'Associados',
    page_title: 'Conheça nossos associados',
    infos: ASSOCIATES
  }
}