import { JacCard } from "../../../../components/JacCard"
import { NAVBAR_PATHS } from "../../../../utils/constants/Paths"
import { CONTENT } from "../../../../utils/mockData/homeContent"

export const Associates = () => {
  return (
    <section
      id={NAVBAR_PATHS.associates}
      className="row align-items-center justify-content-center p-5 m-0 bg-body-secondary"
    >
      <p className="lead text-uppercase text-center"> {CONTENT.associates.breadcrumb} </p>
      <h2 className="text-body-emphasis lh-1 mb-3 text-center">{CONTENT.associates.page_title}</h2>

      <div className="d-flex mb-3 text-center gap-3 pb-3 justify-content-center align-items-center flex-wrap">
        {
          CONTENT.associates.infos.map(({ title, src, url, id, text }) => (
            <JacCard
              key={id}
              cardImageName={src}
              cardTitle={title}
              cardText={text}
              url={url}
            />
          ))
        }
      </div>
    </section>
  )
}