import { JacCard } from "../../../../components/JacCard"
import { NAVBAR_PATHS } from "../../../../utils/constants/Paths"
import { CONTENT } from "../../../../utils/mockData/homeContent"

export const Partners = () => {
  return (
    <section
      id={NAVBAR_PATHS.partners}
      className="row align-items-center justify-content-center p-5 m-0 bg-body-secondary"
    >
      <p className="lead text-uppercase text-center"> {CONTENT.partners.breadcrumb} </p>
      <h2 className="text-body-emphasis lh-1 mb-3 text-center">{CONTENT.partners.page_title}</h2>
      <p className="lead text-center">{CONTENT.partners.description}</p>

      <div className="d-flex mb-3 text-center gap-3 pb-3 justify-content-center align-items-center flex-wrap">
        {
          CONTENT.partners.infos.map(({ title, src, url, id }) => (
            <JacCard
              key={id}
              cardImageName={src}
              cardTitle={title}
              url={url}
              biggerHeight={true}
            />
          ))
        }
      </div>
    </section>
  )
}