import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NAVBAR_PATHS } from "../../../../utils/constants/Paths"
import { openPage } from "../../../../utils/helpers/window"
import { CONTENT } from '../../../../utils/mockData/homeContent'

export const Contact = () => {
  return (
    <section
      id={NAVBAR_PATHS.contact}
      className="row align-items-center justify-content-center p-5 m-0"
    >
      <p className="lead text-uppercase text-center"> {CONTENT.contact.breadcrumb} </p>
      <h2 className="text-body-emphasis lh-1 mb-3 text-center">{CONTENT.contact.page_title}</h2>
      <div className="d-flex flex-column flex-lg-row align-items-lg-start mt-3 justify-content-center">
        {
          CONTENT.contact.infos.map(({ title, icon, texts, id }) => (
            <div className="d-flex p-3 flex-column align-items-center justify-content-center" key={id}>
              {icon && (
                <FontAwesomeIcon className="text-primary fs-1 mb-3" icon={icon} />
              )}
              <h3 className="text-white text-center"> {title} </h3>
              <ul className="list-unstyled m-0 text-center">
                {
                  texts.map(({ label, value, link, id }) => (
                    <li
                      onClick={() => { link && openPage(link) }}
                      key={id}
                      className="text-secondary mb-2"
                      style={{ cursor: link ? 'pointer' : 'default' }}
                    >
                      {label && <span className="d-block mx-2"> {label} </span>}
                      {value && <span className="d-block"> {value} </span>}
                    </li>
                  ))
                }
              </ul>
            </div>
          ))
        }
      </div>
    </section>
  )
}