import { LINKS } from "./links";

export const CONTACTS = [
  // {
  //   id: 1,
  //   title: 'Endereço',
  //   icon: 'location-pin',
  //   texts: [
  //     {
  //       id: 11,
  //       label: 'Unidade Jacarepaguá:',
  //       value: 'Estrada dos Três Rios 90 loja 222, Freguesia',
  //       link: 'https://www.google.com.br/maps/place/Sonix/@-22.9397235,-43.3451502,17z/data=!3m2!4b1!5s0x9bd83307cfafa3:0xcad69cfa295386c!4m6!3m5!1s0x9bd8313c5eb28d:0x9ffdcc355001166a!8m2!3d-22.9397285!4d-43.3425753!16s%2Fg%2F1v3_3xs7?entry=ttu'
  //     }
  //   ]
  // },
  {
    id: 2,
    title: 'E-mail',
    icon: 'envelope',
    texts: [
      {
        id: 21,
        value: 'jacgrupo.rj@gmail.com',
        link: 'mailto:jacgrupo.rj@gmail.com'
      }
    ]
  },
  {
    id: 3,
    title: 'Telefone',
    icon: 'phone-alt',
    texts: [
      {
        id: 33,
        label: 'WhatsApp:',
        value: '(21) 98188-2725',
        link: `${LINKS.wpp}/?text=Digite%20sua%20dúvida`
      },
    ]
  },
  // {
  //   id: 4,
  //   title: 'Horário de atendimento',
  //   icon: 'calendar-alt',
  //   texts: [
  //     {
  //       id: 41,
  //       value: 'Seg. à Sex das 09h às 18h',
  //     },
  //     {
  //       id: 42,
  //       value: 'Sáb. das 09h às 13h',
  //     },
  //   ]
  // }
]