import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button'
import { NAVBAR_PATHS } from '../../utils/constants/Paths';
import { CONTENT } from '../../utils/mockData/homeContent';
import { openPage } from '../../utils/helpers/window';
import { LINKS } from '../../utils/mockData/links';

export const JacNavbar = () => {
  return (
    <header>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand>
            <img src="/assets/logo.png" alt="Logo da Jac Grupos" width="60" height="60" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href={`#${NAVBAR_PATHS.about}`} className="nav-link">
                {CONTENT.navbar.about}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.partners}`} className="nav-link">
                {CONTENT.navbar.partners}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.associates}`} className="nav-link">
                {CONTENT.navbar.associates}
              </Nav.Link>
              <Nav.Link href={`#${NAVBAR_PATHS.contact}`} className="nav-link">
                {CONTENT.navbar.contact}
              </Nav.Link>
            </Nav>

            <Button type="button" onClick={() => openPage(`${LINKS.wpp}/?text=Digite%20sua%20dúvida`)}>
              {CONTENT.navbar.call}
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
