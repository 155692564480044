import { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from 'react-bootstrap/Card'
import { openPage } from '../../utils/helpers/window'

export const JacCard = ({ cardTitle, cardText, cardImageName, url, biggerHeight }) => {
  const CARD_WIDTH = '300px'
  const CARD_MIN_HEIGHT = !biggerHeight ? '350px' : '250px'
  const CARD_HEIGHT = !biggerHeight ? '350px' : '250px'

  const cardBodyRef = useRef()
  const [hasScrollbar, setHasScrollbar] = useState(false)

  const stringsToCheck = ['Veroneze'];
  const biggerImages = stringsToCheck.some(str => cardTitle.indexOf(str) > -1);
  const hasImageAuto = !biggerHeight

  useEffect(() => {
    if (!cardBodyRef?.current) return
    const cardBodyScrollHeight = cardBodyRef.current.scrollHeight
    const cardBodyHeight = cardBodyRef.current.clientHeight
    if (cardBodyScrollHeight > cardBodyHeight) setHasScrollbar(true)
  }, [])

  return (
    <Card
      className="p-3 shadow bg-light text-dark"
      style={{ minWidth: CARD_WIDTH, width: CARD_WIDTH, minHeight: CARD_MIN_HEIGHT, height: CARD_HEIGHT, maxHeight: CARD_HEIGHT, cursor: 'pointer' }}
      onClick={() => url && openPage(url)}
    >
      <div className="flex-grow-1 d-flex align-items-center">
        <Card.Img
          variant="top"
          src={`${cardImageName}`}
          className={biggerImages ? 'w-auto m-auto' : hasImageAuto ? 'm-auto w-auto h-auto' : 'img-fluid'}
          width="168"
          height="168"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>
      <Card.Title className="mt-2">{cardTitle}</Card.Title>
      {
        cardText && (
          <Card.Body className="overflow-y-auto" ref={cardBodyRef}>
            <Card.Text> {cardText} </Card.Text>
          </Card.Body>
        )
      }
      {
        hasScrollbar && (
          <span className="arrow-sinalization">
            <FontAwesomeIcon className="text-primary" icon={['fas', 'arrow-down']} />
          </span>
        )
      }
    </Card>
  )
}
