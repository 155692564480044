export const ASSOCIATES = [
  {
    id: 0,
    title: 'Veroneze Administradora',
    src: `/assets/veroneze.jpg`,
    text: 'Gestão Condominial.',
    url: 'https://www.instagram.com/veronezecondominiosrj/'
  },
  {
    id: 1,
    title: 'AASAT Tecnologia',
    src: `/assets/assat.jpg`,
    text: 'Segurança e Tecnologia Eletrônica.',
    url: 'https://www.instagram.com/aasat.fone/'
  },
  {
    id: 2,
    title: 'Acqua X do Brasil',
    src: `/assets/acquax.jpg`,
    text: 'Individualização e medição de água, gás e energia.',
    url: 'https://www.instagram.com/acquaxdobrasil/'
  },
  {
    id: 3,
    title: 'Triangular elevadores',
    src: `/assets/triangular.jpg`,
    text: 'Manutenção de Elevadores, esteiras e escadas rolantes.',
    url: 'https://www.instagram.com/triangularelevadores/'
  },
  {
    id: 4,
    title: 'Don Bunny',
    src: `/assets/donbunny.jpg`,
    text: 'Representação de equipamentos e serviços de tecnologia na área condominial.',
    url: 'https://www.instagram.com/donbunnyx/'
  },
  {
    id: 5,
    title: 'RH + Gestão e Saúde',
    src: `/assets/rhgestao.png`,
    text: 'Clínica de medicina ocupacional e segurança do trabalho.',
    url: 'https://www.instagram.com/rhgestaosaude/'
  },
]